:root {
    --top-text-width: 100%;
}

.topbar-logos {
    height: 160px;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 15px;
    grid-area: logo;
    align-self: end;
    padding-right: 12px;
}

.topbar {
    border-bottom: #999 4px solid;
    width: 100%;
    background-color: #000;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "text logo";
    overflow: hidden;
}

.topbar-text-container {
    overflow: hidden;
    text-align: left;
    grid-area: text;
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
}

.topbar-text {
    transform: translateX(var(--top-text-width));
    text-align: left;
    color: white;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 60px;
    display: inline-flex;
    align-items: center;
    animation: text-scroll 32s linear infinite;
    margin-right: 100px;
    width: 100%;
    white-space: nowrap;
}


#bl {
    padding-right: 2%;
    height: 85%;
}

.topbar-logo {
    padding-left: 20px;
    height: 50%;
}

@keyframes text-scroll {
    from {
        transform: translateX(var(--top-text-width));
    }
    to {
        transform: translateX(calc(-1 * var(--top-text-width)));
    }
}
