@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,700;1,300&display=swap');

.slide-holder {
    color: #bbb;
    font-family: 'Chakra Petch', sans-serif;
    width: 100%;
}

.slide {
    padding-top: 35px;
    white-space: nowrap;
    padding-right: 50px;
    overflow: hidden;
    text-align: right;
}

.name-area {
    font-weight: 700;
    font-size: 120px;
    background: linear-gradient(var(--angle), var(--col1), var(--col2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 20s linear infinite, colrot 20s linear infinite;
}

.name-holder {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    width: 85%;
}

.subtitle-area {
    font-weight: 300;
    font-size: 60px;
    font-style: italic;
    transform: translateY(-20px);
}

.prompt-area {
    padding-top: 50px;
    font-weight: 300;
    font-size: 50px;
    color: #ccc;
    padding-bottom: 60px;
}

@keyframes gradient {
    0% {
        --angle: 90deg;
        --col1: #bafdec;
        --col2: #7d76db;

    }
    12.5% {
        --angle: 155deg;
    }
    37.5% {
        --angle: 195deg;
    }
    50% {
        --col1: #768edb;
        --col2: #db76a5;
    }
    62.5% {
        --angle: 345deg;
    }
    87.5% {
        --angle: 15deg;
    }
    100% {
        --angle: 90deg;
        --col1: #bafdec;
        --col2: #7d76db;
    }
    

}

@keyframes colrot {
0% {
    filter: hue-rotate(0deg);
  }
  
  50% {
    filter: hue-rotate(180deg);
  }
  
  100% {
    filter: hue-rotate(360deg);
  }
}


@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: false;
}

@property --col1 {
    syntax: '<color>';
    initial-value: #bafdec;
    inherits: false;
}

@property --col2 {
    syntax: '<color>';
    initial-value: #7d76db;
    inherits: false;
}
