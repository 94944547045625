.control-area{
    width: 100%;
    text-align: center;
}

.slide-input-area {
    width: 90%;
    margin: 0 auto;
}

.top-zone{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 4px solid #AAA;
    color: #CCC;
}

.input-box {
    margin-bottom: 20px;
}

.button-zone {
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    color: #CCC;
    width: 50%;
    margin: 0 auto;
}

.MuiButton-root {
    width: 100%;
}

.MuiButtonBase-root {
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}
