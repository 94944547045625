#black {
    height: calc(100% - 200px);
    width: 100%;
    background-color: #000;
    text-align: left;
    overflow: hidden;
}

#black-full {
    height: 100%;
    width: 100%;
    background-color: #000;
    text-align: left;
}


#dvd {
    position: relative;
    left: 0px;
    top: 0px;
}